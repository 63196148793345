<template>
  <div class="video">
    <video
      :src="prefixPath(videopath)"
      autoplay
      muted
      loop
      playsinline
      webkit-playsinline
    ></video>
  </div>
</template>

<script>
import getPathFunction from "@/utilities/composition/getPathFunction";
export default {
  props: { videopath: String },
  setup() {
    const { prefixPath } = getPathFunction();
    return { prefixPath };
  },
};
</script>

<style lang="scss" scoped>
.video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 30%;
}
video {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
