<template>
  <div class="projekt">
    <div class="projekt__wrapper">
      <div class="projekt__content">
        <div class="projekt__item">
          <AppLoveBrands />
          <div class="projekt__hl">projekte</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppLoveBrands from "@/components/ui/buttons/AppLoveBrands.vue";
export default {
  components: {
    AppLoveBrands,
  },
};
</script>

<style lang="scss">
.projekt__item {
  text-align: center;
}

.projekt__hl {
  font-family: $font-family-herohl;
  text-transform: uppercase;
  color: white;

  font-size: 10vw;
  @media screen and (min-width: $xxlarge) {
    font-size: 200px;
  }
}
.projekt__content {
  max-width: $xlarge;
  margin: 0 auto;
  margin-top: 20vh;
  line-height: 1.1;

  @media screen and (min-width: $medium) {
    margin-bottom: 20vh;
  }
  @media screen and (min-width: $large) {
    margin-bottom: 20vh;
  }
  @media screen and (min-width: $xlarge) {
    margin-bottom: 20vh;
  }
}
</style>
