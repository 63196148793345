<template>
  <div class="page page__home">
    <HeaderVideo :videopath="'assets/videos/pvm.mp4'" />
    <LoveBrandsBlock class="section" />
    <SDDBlock class="section" />
    <LoveBrandsBlock class="section" />
    <ProjektBlock class="section" />
    <KundenBlock class="section" />
    <ContactBlock class="section" />
    <GalleryOffice class="section" />
    <TheFooter class="section" />
  </div>
</template>

<script>
import HeaderVideo from "@/components/common/header/HeaderVideo";
import LoveBrandsBlock from "@/components/common/loveBrands/LoveBrandsBlock.vue";
import SDDBlock from "@/components/common/sdd/SDDBlock.vue";
import ProjektBlock from "@/components/common/projekte/ProjektBlock.vue";
import KundenBlock from "@/components/common/kunden/KundenBlock.vue";
import ContactBlock from "@/components/common/contact/ContactBlock.vue";
import GalleryOffice from "@/components/common/gallery/GalleryOffice.vue";
// import IntroBlockHome from "@/components/common/introblock/IntroBlockHome.vue";
import TheFooter from "../components/common/footer/TheFooter.vue";
// import { defineAsyncComponent, ref } from "@vue/runtime-core";

//gsap

// const LoveBrandsBlock = defineAsyncComponent(() =>
//   import(
//     "@/components/common/loveBrands/LoveBrandsBlock.vue" /*webpackChunkName :"LoveBrandsBlock" */
//   )
// );

// const SDDBlock = defineAsyncComponent(() =>
//   import(
//     "@/components/common/sdd/SDDBlock.vue" /*webpackChunkName :"SDDBlock" */
//   )
// );
// const ProjektBlock = defineAsyncComponent(() =>
//   import(
//     "@/components/common/projekte/ProjektBlock.vue" /*webpackChunkName :"ProjektBlock" */
//   )
// );
// const KundenBlock = defineAsyncComponent(() =>
//   import(
//     "@/components/common/kunden/KundenBlock.vue" /*webpackChunkName :"KundenBlock" */
//   )
// );

// const ContactBlock = defineAsyncComponent(() =>
//   import(
//     "@/components/common/contact/ContactBlock.vue" /*webpackChunkName :"ContactBlock" */
//   )
// );
// const GalleryOffice = defineAsyncComponent(() =>
//   import(
//     "@/components/common/gallery/GalleryOffice.vue" /*webpackChunkName :"KundenBlock" */
//   )
// );

import { useStore } from "vuex";

export default {
  components: {
    HeaderVideo,
    LoveBrandsBlock,
    ContactBlock,
    SDDBlock,
    ProjektBlock,
    KundenBlock,
    GalleryOffice,
    TheFooter,
  },

  setup() {
    const store = useStore();
    // MENU STYLINGS:

    return { store };
  },
};
</script>
<style
  lang="
    scss"
></style>
