<template>
  <div class="header--aspectratio" ref="header">
    <div class="header__wrapper" ref="headerWrapper">
      <VideoComponentSameAspekt
        :videopath="videopath"
        :imgposition="imgposition"
      />
    </div>
  </div>
</template>

<script>
import getPathFunction from "@/utilities/composition/getPathFunction";
import { ref } from "@vue/runtime-core";
import VideoComponentSameAspekt from "./VideoComponentSameAspekt.vue";
export default {
  components: { VideoComponentSameAspekt },
  props: { videopath: String, imgposition: String },
  setup() {
    const { setBackGroundPath } = getPathFunction();

    const headerWrapper = ref(null);
    const header = ref(null);

    return { setBackGroundPath, headerWrapper, header };
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 100px;

  @media screen and (min-width: $small) {
    margin-bottom: 150px;
  }
  @media screen and (min-width: $medium) {
    margin-bottom: 207px;
  }
  @media screen and (min-width: $large) {
    margin-bottom: 260px;
  }
  @media screen and (min-width: $xlarge) {
    margin-bottom: 260px;
  }
}
.header--aspectratio {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 50px;

  @media screen and (min-width: $small) {
    margin-bottom: 75px;
  }
  @media screen and (min-width: $medium) {
    margin-bottom: 100px;
  }
  @media screen and (min-width: $large) {
    margin-bottom: 130px;
  }
  @media screen and (min-width: $xlarge) {
    margin-bottom: 130px;
  }
}

.header__wrapper {
  position: relative;
  width: 100%;
}
.header__img {
  position: relative;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
