<template>
  <div class="applovebrands" ref="applovebrands">
    <div class="applovebrands__text">
      love-brands
      <div class="applovebrands__marker"></div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
export default {
  props: { link: String },
  setup(props) {
    const appbutton = ref(null);

    const randomNumb = ref(null);
    onMounted(() => {
      if (props.link) {
        appbutton.value.addEventListener("click", openNewTab);
      }

      function openNewTab(e) {
        e.stopPropagation();
        e.preventDefault();
        randomNumb.value = "?" + Math.floor(Math.random() * 10000000000000000);
        window.open(props.link + randomNumb.value, "_blank");
      }
    });
    return { appbutton, randomNumb };
  },
};
</script>

<style lang="scss">
.applovebrands__text {
  margin: 0 auto;
  font-family: $font-family-herohl;
  color: $main-color-1;
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.2px;
  text-transform: uppercase;

  max-width: 1084px;

  @media screen and (min-width: $medium) {
    margin: 0 auto;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }
}
</style>
