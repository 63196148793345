<template>
  <div class="kunden">
    <div class="kunden__wrapper">
      <AppHl>Kunden</AppHl>
      <div class="kunden__block">
        <div class="kunden__item">
          <KundenPic :imgpath="path + 0 + '.svg'" />
        </div>
        <div class="kunden__item">
          <KundenPic :imgpath="path + 1 + '.svg'" />
        </div>
        <div class="kunden__item">
          <KundenPic :imgpath="path + 2 + '.svg'" />
        </div>
        <div class="kunden__item">
          <KundenPic :imgpath="path + 3 + '.svg'" />
        </div>
        <div class="kunden__item">
          <KundenPic :imgpath="path + 4 + '.svg'" class="hide__small" />
        </div>
        <div class="kunden__item">
          <KundenPic :imgpath="path + 5 + '.svg'" class="hide__small" />
        </div>
        <div class="kunden__item">
          <KundenPic :imgpath="path + 6 + '.svg'" class="hide__small" />
        </div>
        <div class="kunden__item">
          <KundenPic :imgpath="path + 7 + '.svg'" class="hide__small" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHl from "@/components/ui/buttons/AppHl.vue";
import KundenPic from "./KundenPic";
export default {
  components: { KundenPic, AppHl },
  setup() {
    let path = "assets/img/svg/_referenzlogos/Referenzlogos-0";

    return {
      path,
    };
  },
};
</script>

<style></style>
