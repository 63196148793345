<template>
  <img class="kundenpic" :src="getPath(imgpath)" alt="" />
</template>

<script>
import getPathFunction from "@/utilities/composition/getPathFunction";

export default {
  props: { imgpath: String, imgposition: String, motion: String },
  setup() {
    const { getPath } = getPathFunction();

    return { getPath };
  },
};
</script>
