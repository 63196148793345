<template>
  <div class="sddblock">
    <div class="sddblock__wrapper">
      <!-- <AppLoveBrands /> -->
      <div class="ssdblock__item">
        <div class="ssdblock__sec">
          <Strat :allowScroll="true" :pin="true" />
        </div>
      </div>
      <div class="ssdblock__item">
        <div class="ssdblock__sec">
          <Design :allowScroll="true" :pin="true" />
        </div>
      </div>
      <div class="ssdblock__item">
        <div class="ssdblock__sec">
          <Dialog :allowScroll="true" :pin="true" />
        </div>
      </div>
      <div class="ssdblock__item">
        <AppButton class="ssdblock__btn" :routerlink="'leistungen'"
          >Unsere Leistungen</AppButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/ui/buttons/AppButton.vue";
// import AppLoveBrands from "@/components/ui/buttons/AppLoveBrands.vue";

import Strat from "./Strat";
import Dialog from "./Dialog";
import Design from "./Design";
export default {
  components: { Strat, Design, Dialog, AppButton },
};
</script>

<style></style>
